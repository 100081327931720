<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col
            xs="12"
            md="10"
          />
          <b-col
            xs="12"
            md="2"
          >
            <b-button
              to="/external/service_counters/add"
              variant="primary"
              block
            ><FeatherIcon icon="PlusIcon" />
              Ekle</b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(brand)="data">
              <div class="text-danger font-weight-bold">
                {{ data.item.brand }}
              </div>
              <small>{{ data.item.location }}</small>
            </template>
            <template #cell(sdate)="data">
              <div class="text-center">
                <b-badge
                  variant="primary"
                  class="mr-1"
                >
                  {{ moment(data.item.sdate).format('DD.MM.YYYY') }}
                </b-badge>
                <b-badge variant="primary">
                  {{ moment(data.item.edate).format('DD.MM.YYYY') }}
                </b-badge>
              </div>
            </template>
            <template #cell(mechanical)="data">
              <div class="text-center">
                {{ data.item.mechanical }}
              </div>
            </template>
            <template #cell(damage)="data">
              <div class="text-center">
                {{ data.item.damage }}
              </div>
            </template>
            <template #cell(total)="data">
              <div class="text-center">
                {{ data.item.total }}
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                :to="'/external/service_counters/edit/' + data.item.id"
                variant="outline-primary"
                size="sm"
              >
                Güncelle
              </b-button>
            </template>
          </b-table></b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BBadge,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BBadge,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'brand',
          label: 'Marka',
        },
        {
          key: 'sdate',
          label: 'Dönem',
          thStyle: { width: '250px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'mechanical',
          label: 'MEKANİK',
          thStyle: { width: '100px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'damage',
          label: 'HASAR',
          thStyle: { width: '100px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'total',
          label: 'TOPLAM',
          thStyle: { width: '100px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '50px', textAlign: 'left', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_service_counters.id AS id',
          'com_service_counters.sdate AS sdate',
          'com_service_counters.edate AS edate',
          'com_service_counters.mechanical AS mechanical',
          'com_service_counters.damage AS damage',
          '(com_service_counters.damage + com_service_counters.mechanical) AS total',
          'com_brand.name AS brand',
          'com_location.title AS location',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['serviceCounters/getService_counters']
      return data
    },
    dataCount() {
      return this.$store.getters['serviceCounters/getService_countersCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('serviceCounters/service_countersList', this.dataQuery)
    },
  },
}
</script>

<style scoped>

</style>
